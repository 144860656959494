import React, { useState, useEffect } from "react";
// import OrderBook from "../order_book/Order-Book.jsx";
// import { useOrderContext } from "../../../contexts/Order-Context.js";
import Spinner from "../../utils/spinner/Spinner";
import { useSymbolContext } from "../../../contexts/Symbol-Context.js";
import "./Order-Panel.scss";
import { BsArrowUpSquare, BsArrowDownSquare } from "react-icons/bs";
import { GoDotFill } from "react-icons/go";
import Select from "react-select";
import MarketOrder from "../market_order/Market-Order.tsx";
import NotificationsDropdown from "../notifications/NotificationDropdown.jsx"
import PendingOrder from "../pending_order/Pending-Order.tsx";
import { useAuthContext } from "../../../contexts/Auth-Context.js";
import UTCTimeConverter from "./UTCTimeConverter.jsx";
// import { CustomOption, CustomMenuList } from "./CustomOption .jsx";


import { Ripple } from 'react-ripple-click';
import 'react-ripple-click/dist/index.css';
import useIsTablet from "../../../lib/hooks/useIsTablet.js";

// Developed explicit Component for showing ask and bid price in the select symbol option
import { components } from 'react-select';
import ResetAllSettings from "../../../utils/ResetAllSettings.js";
import { formatPrice } from "../../../utils/format.js";
import ServerTime from "./ServerTime.jsx";

const CustomOption = ({ innerRef, innerProps, data }) => {
  const [prevBid, setPrevBid] = useState(data.bid);
  const [prevAsk, setPrevAsk] = useState(data.ask);
  const [bidColor, setBidColor] = useState('#2ecc71'); // Initial color green
  const [askColor, setAskColor] = useState('#2ecc71'); // Initial color green

  useEffect(() => {
    if (data.bid !== prevBid) {
      setBidColor(data.bid > prevBid ? '#2ecc71' : '#e74c3c');
      setPrevBid(data.bid);
    }

    if (data.ask !== prevAsk) {
      setAskColor(data.ask > prevAsk ? '#2ecc71' : '#e74c3c');
      setPrevAsk(data.ask);
    }
  }, [data.bid, data.ask, prevBid, prevAsk]);

  // const formatPrice = (price) => {
  //   if (price === undefined) return "";
  //   const formattedPrice = price.toFixed(4);
  //   const [whole, fractional] = formattedPrice.split('.');
  //   const displayedFractional = fractional.length > 3 ? fractional.slice(0, 3) : fractional;
  //   return (
  //     <span>
  //       {whole}.
  //       <span className="fractional">{displayedFractional}</span>
  //     </span>
  //   );
  // };

  return (
    <div ref={innerRef} {...innerProps} className="custom-option">
      <div className="symbol" style={{ color: '#ffffff' }}>{data.label}</div>
      <div className="bid" style={{ color: bidColor }}>
        {formatPrice(data.bid)}
      </div>
      <div className="ask" style={{ color: askColor }}>
        {formatPrice(data.ask)}
      </div>
    </div>
  );
};

const CustomMenuList = (props) => {
  return (
    <components.MenuList {...props}>
      <div className="header">
        <div className="symbol-title">Symbol</div>
        <div className="bid-title">Bid</div>
        <div className="ask-title">Ask</div>
      </div>
      {props.children}
    </components.MenuList>
  );
};


// Actual code of Order panel starts from here 

const OrderPanel: React.FC = () => {
  // const { font, setFont,  } = useOrderContext();
  let prePositionMarkerState = 'show';
  if (localStorage.positionMarker !== undefined) {
    prePositionMarkerState = localStorage.positionMarker;
  } else {
    localStorage.setItem('positionMarker', prePositionMarkerState);
  }
  const [positionMarker, setPositionMarker] = useState(prePositionMarkerState);
  const setPositionMarkerValue = (e) => {
    setPositionMarker(e);
    localStorage.setItem('positionMarker', e);
  }
  const fontSelecter = (e) => {
    setFont(e.target.value);
    localStorage.setItem("storedFont", e.target.value);
    fontAdjuster(e);
  };


  //CONTEXT
  const {
    loadingSymbolContext,

    symbolInfo,
    marketHours,
    symbolData,
    updateSymbolData,
    allSymbolOptions,
    selectedSymbolOption,
    handleSymbolOptionChange,
    leverage,
    hideLeverageCard,
    activeLvg
  } = useSymbolContext();
  // console.log(allSymbolOptions)
  const { platFromData, selectedAuthSymbol } =
    useAuthContext();
  const { fontAdjuster, fontUpdating } = useAuthContext();
  let toggleTooltipLocal;
  if (localStorage.chartTooltips !== undefined) {
    toggleTooltipLocal = localStorage.chartTooltips;
  } else {
    localStorage.setItem('chartTooltips', 'show');
    toggleTooltipLocal = 'show';
  }
  const [toggleTooltip, setToggleTooltip] = useState(toggleTooltipLocal);
  // const [fontSize, setFontSize] = useState(10);
  const [font, setFont] = useState(localStorage.getItem('storedFont') || 'Large');

  const largeRef = React.useRef();

  const resetChanges = () => {
    const c: boolean = window.confirm(
      "Are you sure you want to reset all the changes from this browser?"
    );
    if (c) {
      const makeSure = () => {
        for (let i = 0; i < localStorage.length; i++) {
          if (
            localStorage.key(i) !== "userId" &&
            localStorage.key(i) !== "userData" &&
            localStorage.key(i) !== "variantId"
          ) {
            localStorage.removeItem(localStorage.key(i));
            if (localStorage.length !== 1) {
              makeSure();
              window.location.reload();
            }
          }
        }
      };
      makeSure();
    }
  };
  //LOcal States
  const [activeMode, setActiveMode] = useState("order");
  const [selectedTab, setSelectedTab] = useState("market");
  useEffect(() => {
    if (localStorage.getItem("activeMode") != null) {
      setActiveMode(localStorage.getItem("activeMode"));
    }
    if (localStorage.getItem("orderTab") != null) {
      setSelectedTab(localStorage.getItem("orderTab"));
    }
    // if (localStorage.getItem("directions") != null) {
    //   setSelectedTab(localStorage.getItem("direction"));
    // }
  }, [localStorage.getItem("activeMode"), localStorage.getItem("orderTab"), localStorage.getItem("direction")]);

  const getMinimumLeverage = (userLeverage, groupLeverage, symbolLeverage) => {

    const parsedUserLeverage = parseFloat(userLeverage);
    const parsedGroupLeverage = parseFloat(groupLeverage);
    const parsedSymbolLeverage = parseFloat(symbolLeverage);
    // If groupLeverage is defined and not NaN
    if (groupLeverage !== undefined && groupLeverage !== null && groupLeverage !== 'null' && !isNaN(parsedGroupLeverage)) {
      return Math.min(parsedUserLeverage, parsedGroupLeverage);
    }

    // If symbolLeverage is defined and not NaN
    if (symbolLeverage !== undefined && symbolLeverage !== null && symbolLeverage !== 'null' && !isNaN(parsedSymbolLeverage)) {
      return Math.min(parsedUserLeverage, parsedSymbolLeverage);
    }

    // Default to userLeverage or 1 if userLeverage is NaN
    return isNaN(parsedUserLeverage) ? 1 : parsedUserLeverage;
  };
  //  var rightCollapse;
  //   if(localStorage.orderPanelToggle ===  undefined){
  //     rightCollapse = {
  //       orders: true,
  //       symbols: false,
  //       market_hours: false,
  //       leverage: false,
  //     }
  //   }else{
  //     rightCollapse = JSON.parse(localStorage.orderPanelToggle);
  //   }

  // Tablet font size adjustment

  // const isTablet = useIsTablet();

  // useEffect(() => {
  //   if (isTablet) {
  // const tempObj = { target: { value: 'Large' } };
  // setFont('Large');
  // localStorage.setItem("storedFont", 'Large');
  // fontAdjuster(tempObj);
  //   }
  // }, [isTablet]);

  useEffect(() => {
    // TODO: use an alternative to useRef
    if (largeRef.current && localStorage.getItem('storedFont') == null) {
      const tempObj = { target: { value: 'Large' } };
      // setFont('Large');
      localStorage.setItem("storedFont", 'Large');
      fontAdjuster(tempObj);
    }
  }, [largeRef.current]);

  const [collapseState, setCollapseState] = useState({
    orders: true,
    symbols: false,
    market_hours: false,
    leverage: false,
  });
  // localStorage.setItem('orderPanelToggle',JSON.stringify(collapseState));

  // Determine classes based on activeMode
  const orderPanelClass = activeMode === "order" ? "visible" : "hidden";
  // const domClass = activeMode === "dom" ? "visible" : "hidden";
  const settingsClass = activeMode === "settings" ? "visible" : "hidden";

  const handleModeClick = (mode: string) => {
    setActiveMode(mode);
    localStorage.setItem("activeMode", mode);
  };

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    localStorage.setItem("orderTab", tab);
  };

  //const getEntryPrice = () => (entryPrice || (buyActive ? bidPrice : askPrice));
  // const getEntryPrice = () => (buyActive ? bidPrice : askPrice);
  //#endregion

  //show spinner while loading data
  if (loadingSymbolContext) {
    return <Spinner />;
  }

  const handleCollapseClick = (section) => {
    setCollapseState((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };
  const orderStyles = {
    container: (provided) => ({
      ...provided,
      display: 'flex',
      justifyContent: 'center',
    }),
    control: (provided, state) => {
      return {
        ...provided,
        paddingLeft: '10px !important',
        boxShadow: state.isFocused ? '0 0 50px #484848 !important' : provided.boxShadow,
        '&:hover': {
          borderColor: 'rgb(33, 196, 109) !important',
          cursor: 'pointer',
          boxShadow: '0 0 0 transparent !important',
        },
        backgroundColor: '#232323 !important',
        borderColor: '#484848 !important',
        // width: 'calc(100% - 1.6rem) !important',
        width: 'calc(100% - 0.9rem) !important',
        cursor: 'pointer',
        // margin: '0.3rem 0.8rem 0 0.8rem !important',
        margin: '0 auto !important',
      };
    },
    singleValue: (provided, state) => ({
      ...provided,
      paddingLeft: '10px !important',
      borderColor: state.isFocused ? 'rgb(33, 196, 109) !important' : '#484848 !important',
      color: '#c5c5c5 !important',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      backgroundColor: '#484848 !important',
      borderColor: state.isFocused ? 'rgb(33, 196, 109) !important' : '#484848 !important',
      color: '#484848 !important',
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '0.3rem !important',
      cursor: 'pointer',
      borderBottom: '1px solid #232323',
      '&:nth-last-child(1)': {
        borderBottom: 'none !important',
      },
      '&:hover': {
        backgroundColor: 'rgb(33, 196, 109) !important',
        color: '#2d2d2d !important',
      },
      backgroundColor: state.isSelected ? '#232323 !important' : 'transparent',
      color: state.isSelected ? 'rgb(33, 196, 109) !important' : '#c5c5c5 !important',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#3b3a3a !important',
      zIndex: 111,
      // boxShadow: '0 0 50px rgba(0, 0, 0, 0.53)',
      // width: 'calc(100% - 1.6rem) !important',
      // margin: '0.3rem 0.8rem 0 0.8rem !important',
      width: 'calc(100% - 2.25rem) !important',
      margin: '0 auto !important',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '50vh',
      overflowY: 'auto',
      padding: '20px !important'
    }),
  };
  return (
    <div className="global-platfrom">
      <div className="global-platform-order-child">
        <div className="mode-tabs" >
          <button
            className={`mode-tab-button ${activeMode === "order" ? "mode-tab-active" : ""
              }`}
            onClick={() => {
              handleModeClick("order");
              // mkRipple(e)
            }}
            style={{
              position: 'relative',
              overflow: 'hidden',
              isolation: 'isolate',
            }}
          >
            <Ripple
            // color="rgba(255, 255, 255, 0.3)" duration={0.5}
            />
            Order
          </button>
          {/* <button
          className={`mode-tab-button ${activeMode === "dom" ? "mode-tab-active" : ""
            }`}
          onClick={() => {
            handleModeClick("dom");
            // mkRipple(e);
          }}
          style={{
            position: 'relative',
            overflow: 'hidden',
            isolation: 'isolate',
          }}
        >
          <Ripple />
          DOM
        </button> */}
          <button
            className={`mode-tab-button ${activeMode === "settings" ? "mode-tab-active" : ""
              }`}
            onClick={() => {
              handleModeClick("settings");
              // mkRipple(e);
            }}
            style={{
              position: 'relative',
              overflow: 'hidden',
              isolation: 'isolate',
            }}
          >
            <Ripple />
            Settings
          </button>
          <NotificationsDropdown />
        </div>
        <div className={`order-panel ${orderPanelClass}`}>
          <div className="symbol-selector-container">
            <Select className="testclass100"
              value={selectedSymbolOption}
              onChange={handleSymbolOptionChange}
              options={allSymbolOptions}
              isSearchable
              placeholder="Select Symbol"
              components={{ Option: CustomOption, MenuList: CustomMenuList }}
            />

          </div>
          <div
            className="collapse-card-header"
            onClick={() => handleCollapseClick("orders")}
          >
            <label htmlFor="text">New Order</label>
            <div className="arrow-icons">
              <BsArrowUpSquare
                className={`${collapseState["orders"] ? "visible" : "hidden"}`}
              />
              <BsArrowDownSquare
                className={`${!collapseState["orders"] ? "visible" : "hidden"}`}
              />
            </div>
          </div>
          <div
            className={`collapse-card-container`}
            role={`${collapseState["orders"] ? "visible" : "hidden"}`}
          >
            <div className="collapse-card-wrapper">
              <div className="flex-container">
                <div>{selectedAuthSymbol}</div>
                {platFromData[1]?.dailyChange && (
                  <div className={parseFloat(platFromData[1].dailyChange.split('(')[0]) >= 0 ? 'positive' : 'negative'}>
                    {platFromData[1]?.dailyChange}
                  </div>
                )}
              </div>




              <div className="order-tabs">
                <button
                  className={`tab-button ${selectedTab === "market" ? "order-tab-active" : ""
                    }`}
                  onClick={() => {
                    handleTabClick("market");
                    // mkRipple(e);
                  }}
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    isolation: 'isolate',
                  }}
                >
                  <Ripple />
                  Market
                </button>
                <button
                  className={`tab-button ${selectedTab === "pending-order" ? "order-tab-active" : ""
                    }`}
                  onClick={() => {
                    handleTabClick("pending-order");
                    // mkRipple(e);
                  }}
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    isolation: 'isolate',
                  }}
                >
                  <Ripple />
                  Pending Order
                </button>
              </div>
              <div
                className={`order-section ${selectedTab === "market" ? "visible" : "hidden"
                  }`}
                id="market"
              >

                <MarketOrder selectedOrderTab={selectedTab} />
              </div>
              <div
                className={`order-section ${selectedTab === "pending-order" ? "visible" : "hidden"
                  }`}
                id="market"
              >
                <PendingOrder selectedOrderTab={selectedTab} />
              </div>
            </div>
          </div>

          {/* Symbols Info */}
          <div
            className="collapse-card-header"
            onClick={() => handleCollapseClick("symbols")}
          >
            <label htmlFor="text">Symbol Info</label>
            <div className="arrow-icons">
              <BsArrowUpSquare
                className={`${collapseState["symbols"] ? "visible" : "hidden"}`}
              />
              <BsArrowDownSquare
                className={`${!collapseState["symbols"] ? "visible" : "hidden"}`}
              />
            </div>
          </div>
          <div
            className={`collapse-card-container`}
            role={`${collapseState["symbols"] ? "visible" : "hidden"}`}
          >
            <div className="collapse-card-wrapper">
              <div className="symbol-info-panel">
                <ul className="symbol-info-list">
                  {/* <li>
                  <strong>Base Asset</strong>{" "}
                  <span>{symbolInfo?.base_asset}</span>
                </li>
                <li>
                  <strong>Quote Asset</strong>{" "}
                  <span>{symbolInfo?.quote_asset}</span>
                </li> */}
                  <li>
                    <strong>Pip Position</strong>{" "}
                    <span>{symbolInfo?.pip_position}</span>
                  </li>
                  <li>
                    <strong>Lot Size</strong> <span>{symbolInfo?.lot_size}</span>
                  </li>
                  <li>
                    <strong>Swap (long)</strong>{" "}
                    <span>{symbolInfo?.swap_long} pips</span>
                  </li>
                  <li>
                    <strong>Swap (short)</strong>{" "}
                    <span>{symbolInfo?.swap_short} pips</span>
                  </li>
                  <li>
                    <strong>3-day swaps</strong>{" "}
                    <span>{symbolInfo?.three_day_swaps}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Markets sections */}
          <div
            className="collapse-card-header"
            onClick={() => handleCollapseClick("market_hours")}
          >
            <label htmlFor="text">Market Hours</label>
            <div className="arrow-icons">
              <BsArrowUpSquare
                className={`${collapseState["market_hours"] ? "visible" : "hidden"
                  }`}
              />
              <BsArrowDownSquare
                className={`${!collapseState["market_hours"] ? "visible" : "hidden"
                  }`}
              />
            </div>
          </div>
          <div
            className={`collapse-card-container`}
            role={`${collapseState["market_hours"] ? "visible" : "hidden"}`}
          >
            <div className="collapse-card-wrapper">
              <div className="market-hours-panel">
                <ul className="market-hours-list">
                  {Array.isArray(marketHours) &&
                    marketHours.map((market_hours, index) => {
                      return (
                        <li key={index}>
                          <strong>
                            {market_hours.start_day} {market_hours.start_time} -{" "}
                            {market_hours.end_time}
                          </strong>{" "}
                          <span
                          // className={`${
                          //   market_hours.is_active == true
                          //     ? "visible"
                          //     : "hidden"
                          // }`}
                          >
                            <GoDotFill className={market_hours.is_active == true ? 'svg-green' : 'svg-red'}
                            // color={}
                            />
                          </span>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>

          {/* leverage sections */}
          <div>
            {leverage && leverage.length > 0 && hideLeverageCard && (
              <div>
                <div
                  className="collapse-card-header"
                  onClick={() => handleCollapseClick("leverage")}
                >
                  <label htmlFor="text">Leverage</label>
                  <div className="arrow-icons">
                    <BsArrowUpSquare
                      className={`${collapseState["leverage"] ? "visible" : "hidden"
                        }`}
                    />
                    <BsArrowDownSquare
                      className={`${!collapseState["leverage"] ? "visible" : "hidden"
                        }`}
                    />
                  </div>
                </div>
                <div
                  className={`collapse-card-container`}
                  role={`${collapseState["leverage"] ? "visible" : "hidden"}`}
                >
                  <div className="collapse-card-wrapper">
                    <div className="leverage-panel">
                      <ul className="leverage-list">
                        <li className="leverage-heading">
                          <strong>Volume</strong>
                          <strong>Leverage</strong>
                          <strong>Status</strong>
                        </li>
                        {/* {
                          console.log(leverage[0],"leverage for map")
                        } */}
                        {leverage && Array.isArray(leverage[0]?.group_level_leverage) && leverage[0]?.group_level_leverage.length > 0 ? (
                          (() => {
                            // Check if activeLvg matches all getMinLvgVar in group_level_leverage
                            const allMatch = leverage[0].group_level_leverage.every(lvg => {
                              const minLvgVar = getMinimumLeverage(leverage[0].user_default_leverage, lvg.max_leverage, []);
                              return activeLvg === minLvgVar;
                            });

                            return leverage[0]?.group_level_leverage.map((lvg, index) => {
                              const isLast = index === leverage[0].group_level_leverage.length - 1;
                              const getMinLvgVar = getMinimumLeverage(leverage[0].user_default_leverage, lvg.max_leverage, []);

                              return (
                                <li key={index}>
                                  <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className="marginRight" style={{ textAlign: 'center', fontSize: '1.5em' }}>{isLast ? '∞' : '≤'}</span>
                                    <span style={{ textAlign: 'center', fontSize: '1.0em' }}>{lvg.exposure_level}</span>
                                  </span>
                                  <span>1:{getMinLvgVar}</span>{" "}
                                  <span>
                                    <GoDotFill className={
                                      allMatch && isLast
                                        ? 'svg-green'
                                        : activeLvg == getMinLvgVar && !allMatch
                                          ? 'svg-green'
                                          : 'svg-red'
                                    } />
                                  </span>
                                </li>
                              );
                            });
                          })()
                        )
                          : leverage && Array.isArray(leverage[0]?.symbol_default_leverage) && leverage[0]?.symbol_default_leverage.length > 0 ? (
                            (() => {
                              // Check if activeLvg matches all getMinLvgVar in symbol_default_leverage
                              const allMatch = leverage[0].symbol_default_leverage.every(lvg => {
                                const minLvgVar = getMinimumLeverage(leverage[0].user_default_leverage, [], lvg.max_leverage);
                                return activeLvg === minLvgVar;
                              });

                              return leverage[0]?.symbol_default_leverage.map((lvg, index) => {
                                const isLast = index === leverage[0].symbol_default_leverage.length - 1;
                                const getMinLvgVar = getMinimumLeverage(leverage[0].user_default_leverage, [], lvg.max_leverage);

                                return (
                                  <li key={index}>
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                      <span className="marginRight" style={{ textAlign: 'center', fontSize: '1.5em' }}>{isLast ? '∞' : '≤'}</span>
                                      <span style={{ textAlign: 'center', fontSize: '1.0em' }}>{lvg.exposure_level}</span>
                                    </span>
                                    <span>1:{getMinLvgVar}</span>{" "}
                                    <span>
                                      <GoDotFill className={
                                        allMatch && isLast
                                          ? 'svg-green'
                                          : activeLvg == getMinLvgVar && !allMatch
                                            ? 'svg-green'
                                            : 'svg-red'
                                      } />
                                    </span>
                                  </li>
                                );
                              });
                            })()
                          ) : <li >
                            <span> </span>{" "}
                            <span>1:{leverage[0].user_default_leverage} </span>
                            <span><GoDotFill className={activeLvg == parseInt(leverage[0].user_default_leverage) ? 'svg-green' : 'svg-red'} /></span>
                          </li>
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <div className={`order-book-wrapper ${domClass}`}>
        <OrderBook />
      </div> */}
        <div className={`settings-right-panel ${settingsClass}`}>
          <div className="font-adjuster">
            <h2>Adjust Font Size</h2>
            <div>
              <ul>
                <li>
                  <label htmlFor="largeFontRadio" role={font === "Large" ? "true" : "false"} className="font-sizes">
                    <input
                      ref={largeRef}
                      onChange={fontSelecter}
                      type="radio"
                      name="fontSelecter"
                      value="Large"
                      id="largeFontRadio"
                      hidden
                      checked={font === "Large"}
                    />
                    <span style={{ fontSize: "14px" }}>Large</span>
                    <div className="custom-radio-box"></div>
                  </label>
                </li>
                <li>
                  <label htmlFor="defaultFontRadio" role={font === "Default" ? "true" : "false"} className="font-sizes">
                    <input
                      onChange={fontSelecter}
                      type="radio"
                      name="fontSelecter"
                      value="Default"
                      id="defaultFontRadio"
                      hidden
                      checked={font === "Default"}
                    />
                    <span style={{ fontSize: "12px" }}>Normal</span>
                    <div className="custom-radio-box"></div>
                  </label>
                </li>
                <li>
                  <label htmlFor="smallFontRadio" role={font === "Small" ? "true" : "false"} className="font-sizes">
                    <input
                      onChange={fontSelecter}
                      type="radio"
                      name="fontSelecter"
                      value="Small"
                      id="smallFontRadio"
                      hidden
                      checked={font === "Small"}
                    />
                    <span style={{ fontSize: "10px" }}>Small</span>
                    <div className="custom-radio-box"></div>
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div className="font-adjuster -setting-panel-divider">
            <h2>Toggle Position Markers</h2>
            <ul>
              <li>
                <label
                  htmlFor="openPosition"
                  role={positionMarker === "open" ? "true" : "false"}
                >
                  <input
                    onChange={() => setPositionMarkerValue('open')}
                    type="radio"
                    id="openPosition"
                    name="positionMarker"
                    hidden
                  />
                  <span>Position Open</span>
                  <div className="custom-radio-box"></div>
                </label>
              </li>
              <li>
                <label
                  htmlFor="closePosition"
                  role={positionMarker === "close" ? "true" : "false"}
                >
                  <input
                    onChange={() => setPositionMarkerValue('close')}
                    type="radio"
                    id="closePosition"
                    name="positionMarker"
                    hidden
                  />
                  <span>Position Close</span>
                  <div className="custom-radio-box"></div>
                </label>
              </li>
              <li>
                <label
                  htmlFor="showAll"
                  role={positionMarker === "show" ? "true" : "false"}
                >
                  <input
                    onChange={() => setPositionMarkerValue('show')}
                    type="radio"
                    id="showAll"
                    name="positionMarker"
                    hidden
                  />
                  <span>Show all markers</span>
                  <div className="custom-radio-box"></div>
                </label>
              </li>
              <li>
                <label
                  htmlFor="hideAll"
                  role={positionMarker === "hide" ? "true" : "false"}
                >
                  <input
                    onChange={() => setPositionMarkerValue('hide')}
                    type="radio"
                    id="hideAll"
                    name="positionMarker"
                    hidden
                  />
                  <span>Hide all markers</span>
                  <div className="custom-radio-box"></div>
                </label>
              </li>
            </ul>
          </div>
          <div className="font-adjuster -setting-panel-divider">
            <h2>Toggle Candle's Tooltip</h2>
            <ul>
              <li>
                <label
                  htmlFor="showTooltip"
                  role={toggleTooltip === "show" ? "true" : "false"}
                >
                  <input
                    onChange={() => { setToggleTooltip('show'); localStorage.setItem('chartTooltips', 'show'); }}
                    type="radio"
                    id="showTooltip"
                    name="toggleTooltip"
                    hidden
                  />
                  <span>Show</span>
                  <div className="custom-radio-box"></div>
                </label>
              </li>
              <li>
                <label
                  htmlFor="hideTooltip"
                  role={toggleTooltip === "hide" ? "true" : "false"}
                >
                  <input
                    onChange={() => { setToggleTooltip('hide'); localStorage.setItem('chartTooltips', 'hide'); }}
                    type="radio"
                    id="hideTooltip"
                    name="toggleTooltip"
                    hidden
                  />
                  <span>Hide</span>
                  <div className="custom-radio-box"></div>
                </label>
              </li>
            </ul>
          </div>
          <div className="font-adjuster -setting-panel-divider">
            <UTCTimeConverter />
          </div>
          <div className="font-adjuster -setting-panel-divider">
            <ServerTime />
          </div>
          {/* <div className="reset-settings">
          <h2>Reset all the changes</h2>
          <button onClick={resetChanges}>Reset</button>
        </div> */}
          <div className="font-adjuster setting-panel-divider">
            <ResetAllSettings />
          </div>

        </div>
      </div>
    </div>
  );
};

export default OrderPanel;
