import React, { useState, useEffect } from "react";
import Spinner from "../../utils/spinner/Spinner";
import { toast } from "react-toastify";
import { useSymbolContext } from "../../../contexts/Symbol-Context.js";
import { API_ENDPOINT_OPEN_POSITION } from "../../../data/Endpoints-API.js";
import APIMiddleware from "../../../data/api/Api-Middleware.js";
import { useAccountManagerContext } from "../../../contexts/Account-Manager-Context.js";
import { useAuthContext } from "../../../contexts/Auth-Context.js";
import { useMetricsContext } from "../../../contexts/Metrics-Context.js";
import { useOrderContext } from "../../../contexts/Order-Context.js";
import { Position } from "../../../interfaces/Position.js";
import CreatableSelect from 'react-select/creatable';

import { Ripple } from 'react-ripple-click';
import 'react-ripple-click/dist/index.css';

import {
  formatPrice,
  formatPriceUptoDecimals,
  formatPositionToPipSize,
  formatDigitBasePrice,
} from "../../../utils/format.js";
import btcIcon from "../../../imgs/btc.png";
import usdtIcon from "../../../imgs/usdt.png";
import ProfitLoss from "./SLTP.jsx";
import "./Market-Order.scss";
import UTCTimeConverter from "../order_panel/UTCTimeConverter.jsx";
import PriceDrop from "../../priceDrop/PriceDrop.jsx";
interface ChildProps {
  selectedOrderTab: any;
}
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    // width: '350px !important',
    padding: '7px !important',
    color: 'white',
    // marginLeft: '10px !important',
    borderColor: '#484848 !important',
    backgroundColor: state.isFocused ? 'rgb(33, 196, 109) !important' : null,
    '&:hover': {
      backgroundColor: 'rgb(33, 196, 109) !important',
      cursor: 'pointer',
    },
    cursor: 'pointer',
  }),
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    backgroundColor: '#232323 !important',
    borderColor: '#484848 !important',
    boxShadow: state.isFocused ? '#484848 !important' : provided.boxShadow,
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#3b3a3a !important',
    width: '100% !important',
    zIndex: 111,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '120px !important',
    overflowY: 'auto',
    // zIndex: 111,
  }),
  input: (provided) => ({
    ...provided,
      padding: '0 !important'
  }),
};
const MarketOrder: React.FC<ChildProps> = ({selectedOrderTab}) => {
  //CONTEXT
  const {
    loadingSymbolContext,
    bidPrice,
    askPrice,
    symbolInfo,
    marketHours,
    symbolData,
    updateSymbolData,
    allSymbolOptions,
    selectedSymbolOption,
    handleSymbolOptionChange,
    leverage,
    selectedSymbolSession,
    setSelectedSymbolSession,
    quoteCurrency,
    baseCurrency,
    setNewPositionOpen,
    selectedSymbolExchangeRate,
    lotSteps,
    lotSize,
    unitOptions,
    setActiveLvg,
    showPriceAlert
  } = useSymbolContext();
  const { openPosition, updateUserData_Local, setActiveTab } =
    useAccountManagerContext();
  const { user, updateUserData, sendDataToServer ,selectedAuthSymbol, platFromData} =
    useAuthContext();
  const { metrics } = useMetricsContext();

  // #region All states defined here States
  const [pointerEvents, setPointerEvents] = useState("all");
  const [buyActive, setBuyActive] = useState(true);
  const [sellActive, setSellActive] = useState(false);
  const [direction, setDirection] = useState("Buy");
  const [quantity, setQuantity] = useState(0.0);
  const [amount, setAmount] = useState<number | null>(0.0);
  const [entryPrice, setEntryPrice] = useState(0.0);
  const [secondPrice, setSecondPrice] = useState(0.0)
  const [expiresAt, setExpiresAt] = useState("");

  const [slPips, setSlPips] = useState(0.0);
  const [tpPips, setTpPips] = useState(0.0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [preDefineQuantities, setPreDefineQuantities] = useState("false");
  const [assetState, setAssetState] = useState({
    type: "",
    name: "",
  });
  const [orderComment, setOrderComment] = useState("");

  const [assetOptions,setAssetOptions] = useState('false');
  const currentAssetIcon = assetState.name === "USDT" ? usdtIcon : btcIcon;

  const [isPositionOpening, setIsPositionOpening] = useState(false);

  const [takeProfit, setTakeProfit] = useState(0.0);
  const [stopLoss, setStopLoss] = useState(0.0)
  const [selectedLotStep, setSelectedLotStep] = useState(0.0);
  const [selectedLotStepOption, setSelectedLotStepOption] = useState({ value: 0.0, label: '0.0 Lots' });
  const [inputValue, setInputValue] = useState('');
  const [selectedAssetId, setSelectedAssetId] = useState(null)
  const [inputQtyValue, setInputQtyValue] = useState('');
  // const [selectedQty, setSelectedQty] = useState(0.0);
  const [selectedQtyOption, setSelectedQtyOption] = useState({ value: 0.0, label: '0.0' });
  const formatCreateLabelLots = (inputValue) => `${inputValue} Lots`;
  const formatCreateLabelQty = (inputValue) => `${inputValue}`;

  // #endregion


  // useEffect(() => {
  //   setAssetState({type: "base_asset",
  //   name: symbolInfo.base_asset});
  // }, [symbolInfo]);
  useEffect(() => {
    const LSdirection = localStorage.directions;
    if (LSdirection === undefined) {
      handleBuySellOrderClick(true);
      
    } else {
      handleBuySellOrderClick(LSdirection === 'buy' ? true : false);
    }
  }, []);
  
  
  useEffect(() => {
    // setPipSize(0.0001);
    // console.log("assetState", symbolInfo.base_asset);
    setSelectedAssetId(symbolInfo.asset_id)
    // console.log(symbolInfo.asset_id)
    if(symbolInfo.asset_id == 1){
      setAssetState({type: "quote_asset",
        name: symbolInfo.quote_asset});
    }
    else{
      setAssetState({type: "base_asset",
      name: symbolInfo.base_asset});
    }
    if (symbolInfo.trade_type === 'units') {
      const unitFirst = unitOptions?.length > 0 ? unitOptions[0] : { value: 0.0, label: '0.0' };
      // setSelectedQty(parseFloat(unitFirst.value));
      setQuantity(parseFloat(unitFirst.value));
      setSelectedQtyOption(unitFirst);
    } else {
      const lotFirst = lotSteps?.length > 0 ? lotSteps[0] : { value: 0.0, label: '0.0' };
      setSelectedLotStep(parseFloat(lotFirst.value));
      setSelectedLotStepOption(lotFirst);
    }
  }, [lotSteps, symbolInfo, unitOptions]);

  useEffect(() => {
    //If amount entry price and quantity is not null or nan then it will pass to the condition
    if (!isNaN(amount) && !isNaN(entryPrice) && !isNaN(quantity)) {
      //If the selected type is base asset (BTC)
      //then it will set amount according to given quantity
      if (assetState.type === "base_asset") {
        setAmount(entryPrice * quantity);
      }
      //If the selected type is quote asset (USDT)
      //then it will set quantity according to given amount
      else if (assetState.type === "quote_asset") {
        setQuantity(amount / entryPrice);
      }
    }
    else{
      setAmount(null)
    }
  }, [amount, quantity, entryPrice]);

  useEffect(() => {
    setEntryPrice(
      direction == "Buy" ? parseFloat(askPrice) : parseFloat(bidPrice) 
    );
    setSecondPrice(direction == "Buy" ? parseFloat(bidPrice) : parseFloat(askPrice))
    // setEntryPrice(13.93300);
  }, [bidPrice, askPrice,  entryPrice]);

  useEffect(() => {
     setQuantity(selectedLotStep * lotSize)
  }, [selectedLotStep]);
  
  useEffect(() => {
     setQuantity(0.0);
     setAmount(0.0)
  }, [selectedAuthSymbol]);

  const handleProfitChange = (data) => {
    setTakeProfit(data)
  };
  const handleSLPipsChange = (data) => {
    setSlPips(data)
  };
  const handleTPPipsChange = (data) => {
    setTpPips(data)
  };
  const handleLossChange = (data) => {
    setStopLoss(data)
  };

  const handleAssetChange = (e) => {
    // sltp states null on change
    setTakeProfit(0.0);
    setAmount(0.0);
    setQuantity(0.0);
    setInputQtyValue('');
    setSelectedQtyOption({ value: 0.0, label: '0.0' });
    // Create a new object with updated values based on selection
    const updatedAssetState = {
      type: e === symbolInfo.base_asset ? "base_asset" : "quote_asset",
      name: e,
    };
    // Update the state using the updated object
    setAssetState(updatedAssetState);
  };

  const countLetters = (e) => {
    const counter = e.target.value.split("").length;
    const countBox = document.getElementById("count-comment-letters-mo");
    if (counter <= 100 && countBox != null) {
      countBox.innerText = counter;
    } else {
      e.target.value = e.target.value.slice(0, 100);
    }
  };
  const handleDisabledButton = (data) => {
    setIsButtonDisabled(data)
  };
  const handleBuySellOrderClick = (buy: boolean) => {
    setBuyActive(buy);
    setSellActive(!buy);
    setDirection(buy ? "Buy" : "Sell");
    localStorage.setItem('directions', buy ? 'buy' : 'sell');
    setEntryPrice(buy ?   parseFloat(askPrice) : parseFloat(bidPrice));
    setSecondPrice(buy ?  parseFloat(bidPrice) :parseFloat(askPrice))
  };
 
  const placeOrder = () => {
    if (user && user.userId != undefined && user.userId > 0) {
      setActiveTab("open-positions-acc");
      setIsPositionOpening(true);
      placeMarketOrder();
    }
  };
 function calculateNetMarginInti(allOpenedPositions,requiredMargin) {
  // if (!(allOpenedPositions.openedPositions.length > 0)) {
  //   return 0;
  // }
  
 let positions= [... allOpenedPositions.openedPositions ,{symbol:selectedAuthSymbol ,direction ,margin:requiredMargin}]

   const positionsBySymbol = positions.reduce((acc, position) => {
        const symbol = position.symbol;
        const direction = position.direction; // Assume direction is 'Buy' or 'sell'
        const margin = parseFloat(position.margin || 0);

        if (!acc[symbol]) {
            acc[symbol] = { Buy: 0, Sell: 0 };
        }

        if (direction === 'Buy') {
            acc[symbol].Buy += margin;
        } else if (direction === 'Sell') {
            acc[symbol].Sell += margin;
        }
        return acc;
    }, {});

    // Step 2: Calculate net margin
    let totalMargin = 0;
    // console.log(positionsBySymbol,"positionsBySymbol")

    for (const symbol in positionsBySymbol) {
        const { Buy, Sell } = positionsBySymbol[symbol];
        if (Buy > Sell) {
            totalMargin += (Buy - Sell);
        } else {
            totalMargin += (Sell - Buy);
        }
    }
    return totalMargin;
}
 const getMinimumLeverage = (userLeverage, groupLeverage, symbolLeverage) => {

  const parsedUserLeverage = parseFloat(userLeverage);
  const parsedGroupLeverage = parseFloat(groupLeverage);
  const parsedSymbolLeverage = parseFloat(symbolLeverage);
  // If groupLeverage is defined and not NaN
  if (groupLeverage !== undefined && groupLeverage !== null && groupLeverage !== 'null' && !isNaN(parsedGroupLeverage)) {
    return Math.min(parsedUserLeverage, parsedGroupLeverage);
  }

  // If symbolLeverage is defined and not NaN
  if (symbolLeverage !== undefined && symbolLeverage !== null && symbolLeverage !== 'null' && !isNaN(parsedSymbolLeverage)) {
    return Math.min(parsedUserLeverage, parsedSymbolLeverage);
  }

  // Default to userLeverage or 1 if userLeverage is NaN
  return isNaN(parsedUserLeverage) ? 1 : parsedUserLeverage;
};
  const placeMarketOrder = () => {
    if ((buyActive || sellActive) && quantity > 0) {
      // Calculate required margin based on trading logic
      const requiredMargin = calculateRequiredMargin();
    
   
      let useMargin=calculateNetMarginInti(platFromData[3],requiredMargin) 
      let freeMargin;
      let openPositionCheck;
    
     
      if(platFromData[5].margin_calculation == "net"){
     freeMargin =platFromData[5].equity - useMargin
  
    //  console.log(platFromData[5].margin_calculation	,"net ")
    //  console.log(freeMargin,requiredMargin,"freeMargin",platFromData[5].equity , useMargin)
     openPositionCheck = freeMargin > 0
     }else{
     
      freeMargin = metrics.freeMargin;
      openPositionCheck = freeMargin >= requiredMargin
     }
      // Check if the user has enough balance
      if (openPositionCheck ) {
        // Continue with opening the position
        openPosition_api(requiredMargin);
      } else {
        // Show an alert for insufficient balance
        toast.error("Insufficient balance to open the position.", {
          position: "top-right",
        });
        setIsPositionOpening(false);
        document.getElementById("closeSound").play();
        document.querySelector(".deposit-cash")?.setAttribute("view", "true");
        const ig = document.getElementById("quantity-input-guide");
        ig?.setAttribute("shake", "true");
        setTimeout(() => {
          ig?.removeAttribute("shake");
        }, 2000);
      }
    } else {
      toast.error("Quantity should be greater than 0.", {
        position: "top-right",
      });
      document.getElementById("closeSound").play();
      setIsPositionOpening(false);
    }
  };

  //calculate the required margin before open the trade
  const calculateRequiredMargin = () => {
    let reqMarginWithoutLeverage = quantity * getEntryPrice() * selectedSymbolExchangeRate;
// console.log(platFromData[6],"platFromData[6]platFromData[6]platFromData[6]")
if (
  platFromData[6] &&
  platFromData[6].availableLeverage &&
  Array.isArray(platFromData[6].availableLeverage) &&
  platFromData[6].availableLeverage.length > 0 &&
  platFromData[6].availableLeverage[0]?.available_leverage &&
  Array.isArray(platFromData[6].availableLeverage[0].available_leverage) &&
  platFromData[6].availableLeverage[0].available_leverage.length > 0 &&
  Array.isArray(platFromData[6].availableLeverage[0].available_leverage[0]?.group_level_leverage) &&
  platFromData[6].availableLeverage[0].available_leverage[0].group_level_leverage.length > 0
){

      let symbolLeverage = platFromData[6]?.availableLeverage[0]?.available_leverage[0].group_level_leverage.sort((a, b) => {
        // Handle missing or invalid `exposure_level` values using parseFloat
        const aLevel = a && a.exposure_level !== null && a.exposure_level !== undefined
          ? parseFloat(a.exposure_level)
          : Infinity;
        const bLevel = b && b.exposure_level !== null && b.exposure_level !== undefined
          ? parseFloat(b.exposure_level)
          : Infinity;
        return aLevel - bLevel;
      });

 let groupMinLeverage =symbolLeverage.filter(lev => {
    // Ensure `lev.exposure_level` exists and is a valid number before comparison
    const exposureLevel = lev && lev.exposure_level !== null && lev.exposure_level !== undefined
      ? parseFloat(lev.exposure_level)
      : NaN;
    return !isNaN(exposureLevel) && exposureLevel >= reqMarginWithoutLeverage;
  })




let groupDefaultLeverage

if(platFromData[6]?.availableLeverage[0]?.available_leverage[0].group_level_leverage.length > 0 && groupMinLeverage.length ==0){
  // console.log('llllll,',symbolLeverage)
groupDefaultLeverage = symbolLeverage[symbolLeverage.length-1]
}else{
groupDefaultLeverage = groupMinLeverage[0];
}
      
// console.log(groupMinLeverage.length,groupDefaultLeverage,platFromData[6]?.availableLeverage[0]?.available_leverage[0].user_default_leverage, "after groupDefaultLeverage",reqMarginWithoutLeverage);
  
    let minimumLeverage = 1;
    if (platFromData[6]?.availableLeverage[0]?.available_leverage[0]) {
            minimumLeverage = getMinimumLeverage(
              platFromData[6]?.availableLeverage[0]?.available_leverage[0].user_default_leverage,
            groupDefaultLeverage.max_leverage,
            []
        );
    }
    const lvg = minimumLeverage || 1;
    setActiveLvg(lvg);
    
    // console.log(minimumLeverage,"minimumLeverage",lvg)
    // const requiredMargin = quantity * (getEntryPrice() / lvg);
    const converted_entry_price = getEntryPrice() * selectedSymbolExchangeRate;
    const requiredMargin = quantity * (converted_entry_price / lvg);
    // return isNaN(requiredMargin) ? 0 : requiredMargin.toFixed(4);
    return isNaN(requiredMargin)
    ? 0
    :requiredMargin<1?requiredMargin.toFixed(5)
    : Math.round(requiredMargin * 10) / 10
    // : requiredMargin.toFixed(5)
  } else if (
    platFromData[6] &&
    platFromData[6].availableLeverage &&
    Array.isArray(platFromData[6].availableLeverage) &&
    platFromData[6].availableLeverage.length > 0 &&
    platFromData[6].availableLeverage[0]?.available_leverage &&
    Array.isArray(platFromData[6].availableLeverage[0].available_leverage) &&
    platFromData[6].availableLeverage[0].available_leverage.length > 0 &&
    Array.isArray(platFromData[6].availableLeverage[0].available_leverage[0]?.symbol_default_leverage) &&
    platFromData[6].availableLeverage[0].available_leverage[0].symbol_default_leverage.length > 0
  ){
    let symbolLeverage = platFromData[6]?.availableLeverage[0]?.available_leverage[0].symbol_default_leverage.sort((a, b) => {
      // Handle missing or invalid `exposure_level` values using parseFloat
      const aLevel = a && a.exposure_level !== null && a.exposure_level !== undefined
        ? parseFloat(a.exposure_level)
        : Infinity;
      const bLevel = b && b.exposure_level !== null && b.exposure_level !== undefined
        ? parseFloat(b.exposure_level)
        : Infinity;
      return aLevel - bLevel;
    })
    
    let symbolMinLeverage= symbolLeverage.filter(lev => {
      // Ensure `lev.exposure_level` exists and is a valid number before comparison
      const exposureLevel = lev && lev.exposure_level !== null && lev.exposure_level !== undefined
        ? parseFloat(lev.exposure_level)
        : NaN;
      return !isNaN(exposureLevel) && exposureLevel >= reqMarginWithoutLeverage;
    })
  
  
  // console.log(symbolMinLeverage,platFromData[6]?.availableLeverage[0]?.available_leverage[0].user_default_leverage, "before symbol_default_leverage",reqMarginWithoutLeverage);

  let symbolDefaultLeverage

  if(platFromData[6]?.availableLeverage[0]?.available_leverage[0].symbol_default_leverage.length > 0 && symbolMinLeverage.length ==0){
    symbolDefaultLeverage = symbolLeverage[symbolLeverage.length-1]
    }else{
    symbolDefaultLeverage = symbolMinLeverage[0];
    }
          
// console.log(symbolDefaultLeverage,platFromData[6]?.availableLeverage[0]?.available_leverage[0].user_default_leverage, "after symbol_default_leverage",reqMarginWithoutLeverage);
  
let minimumLeverage = 1;
if (platFromData[6]?.availableLeverage[0]?.available_leverage[0]) {
        minimumLeverage = getMinimumLeverage(
        platFromData[6]?.availableLeverage[0]?.available_leverage[0].user_default_leverage,
        [],
        symbolDefaultLeverage.max_leverage,
        
    );
}
const lvg = minimumLeverage || 1;
setActiveLvg(lvg);
// console.log(minimumLeverage,"minimumLeverage",lvg)
// const requiredMargin = quantity * (getEntryPrice() / lvg);
const converted_entry_price = getEntryPrice() * selectedSymbolExchangeRate;
const requiredMargin = quantity * (converted_entry_price / lvg);
// return isNaN(requiredMargin) ? 0 : requiredMargin.toFixed(4);
return isNaN(requiredMargin)
? 0
:requiredMargin<1?requiredMargin.toFixed(5)
: Math.round(requiredMargin * 10) / 10
  }else if (platFromData[6] &&
    platFromData[6].availableLeverage &&
    Array.isArray(platFromData[6].availableLeverage) &&
    platFromData[6].availableLeverage.length > 0 &&
    platFromData[6].availableLeverage[0]?.available_leverage &&
    Array.isArray(platFromData[6].availableLeverage[0].available_leverage) &&
    platFromData[6].availableLeverage[0].available_leverage.length > 0 &&
    platFromData[6]?.availableLeverage[0]?.available_leverage[0].user_default_leverage){

    const lvg = platFromData[6]?.availableLeverage[0]?.available_leverage[0].user_default_leverage || 1;
    setActiveLvg(lvg);
    // console.log("minimumLeverage",lvg)
    // const requiredMargin = quantity * (getEntryPrice() / lvg);
    const converted_entry_price = getEntryPrice() * selectedSymbolExchangeRate;
    const requiredMargin = quantity * (converted_entry_price / lvg);
    // return isNaN(requiredMargin) ? 0 : requiredMargin.toFixed(4);
    return isNaN(requiredMargin)
    ? 0
    :requiredMargin<1?requiredMargin.toFixed(5)
    : Math.round(requiredMargin * 10) / 10

  }
  }



  const getEntryPrice = () => (buyActive ? askPrice : bidPrice);

  //#region API CALLS
  const openPosition_api = async (margin) => {
    try {
      const currentDate = new Date();
      const currentDateTime = new Date(currentDate);

      const data: Position = {
        id: -1,
        position_id: "PID" + Math.floor(100000 + Math.random() * 900000),
        symbol: selectedAuthSymbol,
        quantity: quantity,
        amount: amount,
        asset_type: assetState.type,
        direction: direction,
        entry_price: getEntryPrice(),
        converted_entry_price: getEntryPrice() * selectedSymbolExchangeRate,
        TP: takeProfit,
        SL: stopLoss,
        netEUR: 0, // Set appropriate values
        status: "",
        userId: user.userId,
        // margin: margin,
        exit_price: 0,
        totalUnrealizedPnL: metrics.totalUnrealizedPnL,
        position_closed_at: null,
        comment: orderComment,
        stop_loss_pips: slPips,
        take_profit_pips: tpPips,
        current_exchange_rate: selectedSymbolExchangeRate,
        lot_step: selectedLotStep,
        trade_type: symbolInfo.trade_type,
        lot_size: lotSize
      };
      // console.log(data)
      const response = await APIMiddleware.post(
        API_ENDPOINT_OPEN_POSITION,
        data
      );
      const data_m = {
        ...response.data,
        created_at: new Date(response.data.created_at).toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }),
      };

      // Check if the response data matches the Position interface
      //if (Object.keys(response.data).every((key) => key in data)) {
      // openPosition(data_m);
      sendDataToServer(3);
      // updateUserData(response.data);
      //}

      // Show a success notification
      toast.success("Position opened successfully!", { position: "top-right" });
      setIsPositionOpening(false);
      localStorage.accountManager = "open-positions-acc";
      document.getElementById("openSound").play();
      const getCurrentDateTime = () => {
        var currentDate = new Date();
        var year = currentDate.getFullYear();
        var month = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Adding 1 because months are zero-indexed
        var day = ("0" + currentDate.getDate()).slice(-2);
        var hour = ("0" + currentDate.getHours()).slice(-2);
        var minute = ("0" + currentDate.getMinutes()).slice(-2);
        var currentDateTime = `${year}-${month}-${day} ${hour}:${minute}:00`;
        return currentDateTime;
      };
      if (localStorage.chartBullets === undefined) {
        localStorage.setItem("chartBullets", getCurrentDateTime());
      } else {
        let history = [];
        history.push(localStorage.chartBullets);
        history.push(getCurrentDateTime());
        localStorage.chartBullets = history;
      }
      setNewPositionOpen(Math.floor(Math.random() * 100))
    } catch (error) {
      // Handle API request error
      console.error(`API request error: ${API_ENDPOINT_OPEN_POSITION}`, error);
      setIsPositionOpening(false);
    }
  };

  const changeOrderTab = () => {
    localStorage.orderTab = "pending-order"
  }

  const [focusOut,setFocusOut] = useState(true);

  const handleLotStepOptionChange = (lotStep)=>{
    setSelectedLotStep(parseFloat(lotStep.value));
    setSelectedLotStepOption(lotStep);
  }

  const handleInputChange = (value) => {
    setInputValue(value);
  };


  const handleQtyOptionChange = (option)=>{
    // setSelectedQty(parseFloat(option.value));
    setQuantity(parseFloat(option.value));
    setSelectedQtyOption(option);
  }

  const handleQtyInputChange = (value) => {
    setInputQtyValue(value);
  };

  // const onFocusQty: AriaOnFocus<any> = ({ focused, isDisabled }) => {
  //   if (isDisabled) return '';
  //   const val = parseFloat(focused.value);
  //   // setSelectedQty(val);
  //   setQuantity(val);
  //   setSelectedQtyOption(focused);
  //   return `${focused.label}`;
  // };

  // const onFocusLots: AriaOnFocus<any> = ({ focused, isDisabled }) => {
  //   if (isDisabled) return '';
  //   const val = parseFloat(focused.value);
  //   setSelectedLotStep(val);
  //   setSelectedLotStepOption(focused);
  //   return `${focused.label}`;
  // };

  //#endregion

  //show spinner while loading data
  if (loadingSymbolContext) {
    return <Spinner />;
  }
  const calculateSpread = () => {
    return formatPriceUptoDecimals(Math.pow(10, symbolInfo?.pip_position) * (formatPriceUptoDecimals(askPrice,symbolInfo.digit) - formatPriceUptoDecimals(bidPrice,symbolInfo.digit)),2) 
  }
  return (
    <>
    {showPriceAlert && <PriceDrop/>}
      <div className="focusOutElement" aria-hidden={focusOut} onClick={()=>{
            setAssetOptions('false');
            setFocusOut(true)}} ></div>
 <div className="price-box-container">
        <div
          className={`price-box price-box-buy ${
            buyActive ? "price-box-buy-active" : ""
          }`}
          id="buyMarketOrder"
          onClick={() => handleBuySellOrderClick(true)}
        >
          <div style={{fontSize: '14px', fontWeight: 'bolder'}}>Buy</div>
          <div id="buyMarketPrice" style={{fontSize: '14px'}} >
            {formatPriceUptoDecimals(askPrice, symbolInfo.digit)}
          </div>
        </div>
        <div
          className={`price-box price-box-sell ${
            sellActive ? "price-box-sell-active" : ""
          }`}
          id="sellMarketOrder"
          onClick={() => handleBuySellOrderClick(false)}
        >
          <div style={{fontSize: '14px', fontWeight: 'bolder'}}>Sell</div>
          <div id="sellMarketPrice" style={{fontSize: '14px'}} >
            {formatPriceUptoDecimals(bidPrice, symbolInfo.digit)}
          </div>
        </div>
      </div>
      <div>
        <div>Spread: {calculateSpread()}</div>
      </div>
          {
            selectedAssetId == 1 ? <label htmlFor="quantity" className="order-label">
            Quantity:
          </label> 
          :
           symbolInfo?.trade_type == 'units' ? (
            <label htmlFor="quantity" className="order-label">
              {assetState.type === "quote_asset" ? "Amount: " : "Quantity: "}
            </label>
      ) : (
            <label htmlFor="quantity" className="order-label">
              Lots: 
            </label>
      )}

      {symbolInfo?.trade_type == 'units' ? (

      <div className="volume-amount-container">
        {assetState.type === "base_asset" ? (
          <div className="quantity-div">
            <CreatableSelect
              // aria-labelledby="aria-label"
              // ariaLiveMessages={{
              //   onFocus: (p) => onFocusQty(p),
              // }}
              styles={customStyles}
              onInputChange={handleQtyInputChange}
              inputValue={inputQtyValue}
              value={selectedQtyOption}
              onChange={handleQtyOptionChange}
              options={unitOptions}
              isSearchable
              placeholder="Select Lots"
              formatCreateLabel={formatCreateLabelQty}
              defaultInputValue={inputQtyValue}
              defaultValue={selectedQtyOption}
          />
          </div>
        ) : (
          <div className="amount-div">
            <input
              type="number"
              className="amount-input"
              value={amount}
              onChange={(e) => {
                setAmount(parseFloat(e.target.value));
              }}
            />
          </div>
        )}
        <div className="-select-asset">
          <div className="asset-icon">
            {/* <img src={currentAssetIcon} /> */}
          </div>
          <div 
          className="selectedAsset"
          onClick={()=>{
            setAssetOptions('true');
            setFocusOut(false)}}
          >
            <p>{assetState.name}</p>
            <svg viewBox="0 0 16 16" aria-selected={assetOptions}>
  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
</svg>
            </div>
        </div>
        {
          selectedAssetId != 1 ?  <div className="custom-options-assets" aria-selected={assetOptions}>
            <div className="option-asset" onClick={()=>{handleAssetChange(symbolInfo.base_asset);setAssetOptions('false');setFocusOut(true);}}>
              <div className="left">
                {/* <img src={btcIcon} /> */}
                <p>{symbolInfo.base_asset}</p>
              </div>
            </div>
            <div className="option-asset" onClick={()=>{handleAssetChange(symbolInfo.quote_asset);setAssetOptions('false');setFocusOut(true);}}>
              <div className="left">
                {/* <img src={usdtIcon} /> */}
                <p>{symbolInfo.quote_asset}</p>
              </div>
            </div>
          </div>
          :
          <div className="custom-options-assets" aria-selected={assetOptions}>
            <div className="option-asset" onClick={()=>{handleAssetChange(symbolInfo.quote_asset);setAssetOptions('false');setFocusOut(true);}}>
              <div className="left">
                {/* <img src={usdtIcon} /> */}
                <p>{symbolInfo.quote_asset}</p>
              </div>
            </div>
            <div className="option-asset" onClick={()=>{handleAssetChange(symbolInfo.base_asset);setAssetOptions('false');setFocusOut(true);}}>
              <div className="left">
                {/* <img src={btcIcon} /> */}
                <p>{symbolInfo.base_asset}</p>
              </div>
            </div>
          </div>
        }
      </div>
      ) : (
        <div className="lots-selector-container">
          <CreatableSelect
          // aria-labelledby="aria-label"
          // ariaLiveMessages={{
          //   onFocus: (p) => onFocusLots(p),
          // }}
          styles={customStyles}
          onInputChange={handleInputChange}
          inputValue={inputValue}
          value={selectedLotStepOption}
          onChange={handleLotStepOptionChange}
          options={lotSteps}
          isSearchable
          placeholder="Select Lots"
          formatCreateLabel={formatCreateLabelLots}
          defaultInputValue={inputQtyValue}
          defaultValue={selectedQtyOption}
          />
        </div>
      )

      }   
      <div className="reuqired-margin">
        <div className="title">Required Margin</div>
        <div className="value">
          {calculateRequiredMargin()}{" "}
          {user?.userCurrencyName || "EUR"}
        </div>
      </div>
      {selectedOrderTab === "market"?
     <ProfitLoss isPendingOrder={false} secondPrice={secondPrice} isEditPosition={false} handleDisabledButton={handleDisabledButton} handleSLPipsChange={handleSLPipsChange} handleTPPipsChange={handleTPPipsChange} handleProfitChange={handleProfitChange} handleLossChange={handleLossChange} direction={direction} quantity={quantity} entryPrice={entryPrice}/>
      :<></>}
     
      <div className="text-bx">
        <div className="text-area-letter-counter-sltp">
          <span id="count-comment-letters-mo">0</span>/100
        </div>
        <textarea
          className="comment-sltp-lst"
          placeholder="Comment"
          onInput={(e) => {
            countLetters(e);
          }}
          value={orderComment}
          onChange={(e) => {
            setOrderComment(e.target.value);
          }}
        ></textarea>
      </div>

      <div className="place-order">
        {/* Conditionally set the disabled attribute based on the user's authentication status */}
        <button
          disabled={platFromData[5]?.accessRight == 3|| localStorage.getItem('accountType') =="0"||platFromData[5]?.accessRight == 2||!bidPrice ||!askPrice || isPositionOpening || selectedSymbolSession === 0 || isButtonDisabled}
          className={`place-order-button ${
            buyActive ? "place-order-button-buy" : "place-order-button-sell"
          }`}
          title={platFromData[5]?.accessRight == 3  ? "Trading for this Account in Disabled" :platFromData[5]?.accessRight == 2
            ? "The status of this account is set to Close Only . You can only close your existing Positions" 
            :selectedSymbolSession === 0 ? "Market for this symbol is closed" : ""}
          onClick={() => {
            placeOrder();
            // mkRipple(e);
          }}
          style={{
            position: 'relative',
            overflow: 'hidden',
            isolation: 'isolate',
          }}
        >
          <b style={{fontSize: '14px', fontWeight: 'bolder'}}>
            {!isPositionOpening ? direction : "Loading..."}
          </b>
          <br />
          <span style={{fontSize: '13px'}}>
            {quantity.toFixed(2) || ""} {selectedAuthSymbol} @{" "}
            {buyActive ? formatPrice(askPrice) : formatPrice(bidPrice)}
          </span>
        </button>

        {selectedSymbolSession === 0 ? (
        <button className="mkt-closed-notification-btn" onClick={() => {changeOrderTab()}}>
          {'Market for this symbol is closed'}
        </button>
        ) : platFromData[5]?.accessRight == 3 ? (
          <button className="mkt-closed-notification-btn" onClick={() => {changeOrderTab()}}>
            {"Trading for this Account in Disabled"}
          </button>
          )  : platFromData[5]?.accessRight == 2 ? (
          <button className="mkt-closed-notification-btn" onClick={() => {changeOrderTab()}}>
            {"The status of this account is set to Close Only . You can only close your existing Positions"}
          </button>
          ) :('')}
     
      </div>

    </>
  );
};

export default MarketOrder;
